// ========================================= //
// Typo
// ========================================= //
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
a {
    text-decoration: none;
    transition: 0.3s all ease-in;
}

body {
    font-family: 'Open Sans', sans-serif!important;
}