.faq-element {
    .about-icon {
        img {
            width: 100%;
        }
    }
    .about-content-item {
        display: flex;
        height: rem(138);
        align-items: center;
        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(27);
            text-align: justify;
            color: #3F3F3F;
        }
    }
}

.faqAbout {
    position: relative;
    &:before {
        content: "";
        background-image: url(../../../medias/images/bg1.svg);
        width: 900px;
        height: 1034px;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: -200px;
        z-index: -1;
        top: rem(130);
        @include bp-lt($small) {
            content: none;
        }
    }
    .about-right-image {
        background-color: #F2F2F2;
        height: rem(377);
        border-radius: rem(21);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        padding-left: rem(40);
        padding-right: rem(40);
        margin-top: 0;
        img {
            width: rem(108);
        }
        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #3F3F3F;
            margin-top: rem(20);
            strong {
                font-weight: 800;
            }
        }
    }
}

.faqAbout {
    margin-top: rem(50);
}

.headlign-about {
    h4 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: #272727;
        margin-bottom: rem(60);
    }
    h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 39.11px;
        line-height: 53px;
        color: #272727;
        margin-bottom: rem(10);
    }
}

.faq-listing {
    margin-top: rem(50);
    h4 {
        color: #FF7769;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 34px;
        line-height: 49px;
        margin-bottom: rem(20);
    }
    .accordion-button.collapsed {
        background-color: transparent;
        border-top: none;
        border-bottom: rem(1) solid #83B56F;
        padding-left: 0;
        color: #272727;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: rem(25);
    }
    .accordion-button {
        background-color: transparent;
        border-top: none;
        border-bottom: rem(1) solid #83B56F;
        padding-left: 0;
        color: #83B56F;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: rem(25);
        &:focus {
            box-shadow: none;
        }
    }
    .accordion-item {
        border: none;
    }
    .accordion-body {
        padding-left: rem(0);
        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-align: justify;
            color: #3F3F3F;
        }
    }
}