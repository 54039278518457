footer {
    background-image: url("../../../medias/images/footer.svg");
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin-top: rem(80);
    padding-top: rem(100);
    color: #fff;
    font-family: 'Open Sans', sans-serif!important;
    a {
        color: #ffffff;
        font-family: 'Open Sans', sans-serif!important;
    }
}

.last-footer {
    border-top: rem(1) solid #ffffff;
    padding-top: rem(15);
    padding-bottom: rem(15);
    p {
        font-style: normal;
        font-weight: 400;
        font-size: rem(17);
        line-height: 30px;
        color: #FEFEFE;
        font-family: 'Open Sans', sans-serif!important;
        @include bp-lt($small) {
            text-align: center;
        }
    }
    ul {
        display: flex;
        justify-content: space-between;
        @include bp-lt($small) {
            display: none;
        }
        li {
            display: inline;
            a {
                font-style: normal;
                font-weight: 400;
                font-size: rem(17);
                line-height: 30px;
                /* identical to box height */
                color: #FEFEFE;
                font-family: 'Open Sans', sans-serif!important;
            }
        }
    }
}

.primary-footer {
    padding-bottom: rem(10);
    .contact-footer {
        margin-top: rem(58);
        @include bp-lt($small) {
            margin-top: rem(20);
        }
        li {
            a {
                font-style: normal;
                font-weight: 600;
                font-size: rem(16);
                line-height: rem(38);
                letter-spacing: 0.11em;
                color: #FEFEFE;
                font-family: 'Open Sans', sans-serif!important;
            }
        }
    }
    .adresse-footer {
        margin-top: rem(58);
        @include bp-lt($small) {
            margin-top: rem(20);
        }
        h4 {
            font-style: normal;
            font-weight: 800;
            font-size: 26px;
            line-height: 35px;
            color: #FEFEFE;
            font-family: 'Open Sans', sans-serif!important;
        }
        p {
            font-style: normal;
            font-weight: 600;
            font-size: rem(15);
            line-height: rem(22);
            margin-bottom: rem(10);
            letter-spacing: 0.11em;
            color: #FEFEFE;
            font-family: 'Open Sans', sans-serif!important;
        }
    }
}

.footer-logo {
    @include bp-lt($small) {
        margin-top: rem(20);
    }
}

.footer-social {
    display: flex;
    justify-content: end;
    align-items: end;
    height: rem(173);
    @include bp-lt($small) {
        justify-content: start;
        height: auto;
        margin-top: rem(10);
    }
    li {
        display: inline-block;
        margin: rem(5);
        img {
            position: relative;
            top: 7px;
            left: 7px;
        }
        a {
            background-color: #FFFFFF;
            width: rem(46);
            height: rem(46);
            display: block;
            border-radius: 50%;
        }
    }
}