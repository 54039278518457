.container {
	max-width:rem(1323);
	padding-left: rem(15);
	padding-right: rem(15);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;
	@include bp-lt($small){
		padding-left: rem(20);
		padding-right: rem(20);
	}
}
