.accordion {
    width: 100%;
    border: none;
    .bloc-doc {
        margin-bottom: rem(5);
        @include bp-lt($small) {
            margin-bottom: rem(20);
        }
    }
    .card {
        border: none!important;
        border-radius: 0!important;
    }
    .card-header {
        background-color: $white;
        height: rem(87);
        border-radius: 0!important;
        margin-bottom: 0!important;
    }
    .card-body {
        background-color: $grey--06;
    }
    .collapsible-link {
        width: 100%;
        position: relative;
        text-align: left;
        border: none;
        background-color: transparent;
        font-size: rem(20);
        color: $anthrazit;
        line-height: rem(72);
        font-family: 'legacyBook';
        &:focus {
            outline: none;
        }
    }
    .collapsible-link::before {
        content: '\f107';
        position: absolute;
        top: 50%;
        right: 0.8rem;
        transform: translateY(-50%);
        display: block;
        font-family: 'FontAwesome';
        font-size: 1.1rem;
    }
    .collapsible-link[aria-expanded='true']::before {
        content: '\f106';
    }
}