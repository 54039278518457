            .stepers_container {
                padding-left: rem(70);
                padding-right: rem(70);
                min-height: rem(670);
                max-height: rem(670);
                padding-top: rem(15);
                padding-bottom: rem(20);
                position: relative;
                @include bp-lt($small) {
                    padding-left: rem(15);
                    padding-right: rem(15);
                }
                .step-content {
                    h4 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: rem(18);
                        line-height: rem(27);
                        color: #000000;
                        margin-bottom: rem(20);
                        @include bp-lt($small) {
                            font-size: rem(18);
                        }
                        strong {
                            font-weight: 700;
                        }
                    }
                }
            }
            
            #stepers {
                padding-left: rem(70);
                padding-right: rem(70);
                min-height: rem(667);
                max-height: rem(667);
                padding-top: rem(15);
                padding-bottom: rem(36);
                position: relative;
                .step-content {
                    h4 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: rem(20);
                        line-height: rem(27);
                        color: #000000;
                        margin-bottom: rem(40);
                    }
                    h3 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 27px;
                        text-align: center;
                        color: #000000;
                    }
                }
                .steps_tools {
                    position: absolute;
                    bottom: rem(36);
                    left: rem(65);
                    right: rem(65);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .stepers-btn {
                        span {
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 25px;
                            color: #FFFFFF;
                            padding-left: rem(40);
                            padding-right: rem(40);
                            line-height: rem(38);
                            background-color: #83B56F;
                            border-radius: rem(20);
                            display: inline-block;
                        }
                    }
                    .paginate-steps {
                        width: 100%;
                        height: rem(7);
                        background-color: #F1F0FD;
                        border-radius: rem(5);
                        margin-top: rem(34);
                        position: relative;
                        span {
                            width: 25%;
                            background-color: #83B56F;
                            height: rem(7);
                            position: absolute;
                            top: 0;
                            border-radius: rem(5) rem(0) rem(0) rem(5);
                        }
                    }
                }
            }
            
            .step-headligne {
                text-align: center;
                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: rem(18);
                    line-height: rem(27);
                    text-align: center;
                    color: #000000;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    margin-bottom: rem(28);
                    margin-top: rem(10);
                    margin-top: rem(10);
                }
            }
            /*Progressee bar step*/
            
            #progressbar {
                width: 80%;
                height: 0.4375rem;
                background-color: #F1F0FD;
                border-radius: 0.3125rem;
                margin-top: 2.125rem;
                position: absolute;
                bottom: rem(20);
                left: 10%;
                right: 10%;
                @include bp-lt($small) {
                    bottom: rem(19);
                }
                .active {
                    width: 25%;
                    background-color: #83B56F;
                    height: rem(7);
                    top: 0;
                    //border-radius: rem(5) rem(0) rem(0) rem(5);
                }
            }
            
            #msform {
                border-radius: rem(15);
                width: 45%;
                right: 0;
                position: absolute;
                z-index: 99;
                background: #fff;
                @include bp-lt($small) {
                    position: relative;
                    width: 100%;
                    right: 0;
                }
            }
            
            #msform fieldset .form-card {
                background: white;
                box-sizing: border-box;
                width: 100%;
                position: relative;
                min-height: rem(530);
            }
            
            #msform fieldset {
                background: white;
                border: 0 none;
                border-radius: 0;
                box-sizing: border-box;
                width: 100%;
                margin: 0;
                height: 100%;
            }
            
            #msform fieldset:not(:first-of-type) {
                display: none
            }
            
            #msform .action-button:hover,
            #msform .action-button:focus {
                color: #83B56F;
                background-color: #ffffff;
            }
            
            #msform .action-button-previous:hover,
            #msform .action-button-previous:focus {
                color: #83B56F;
                background-color: #ffffff;
            }
            
            select.list-dt {
                border: none;
                outline: 0;
                border-bottom: 1px solid #ccc;
                padding: 2px 5px 3px 5px;
                margin: 2px
            }
            
            select.list-dt:focus {
                border-bottom: 2px solid skyblue
            }
            
            .card {
                z-index: 0;
                border: none;
                border-radius: 0.5rem;
                position: relative
            }
            
            .fs-title {
                font-size: 25px;
                color: #2C3E50;
                margin-bottom: 10px;
                font-weight: bold;
                text-align: left
            }
            
            #progressbar {
                overflow: hidden;
            }
            
            #progressbar .active {
                color: #000000
            }
            
            #progressbar li {
                list-style-type: none;
                font-size: 12px;
                width: 20%;
                float: left;
                position: relative
            }
            
            #progressbar li:before {
                width: 50px;
                height: 50px;
                line-height: 45px;
                display: block;
                font-size: 18px;
                color: #ffffff;
                background: lightgray;
                border-radius: 50%;
                margin: 0 auto 10px auto;
                padding: 2px
            }
            
            #progressbar li:after {
                content: '';
                width: 100%;
                height: 2px;
                background: lightgray;
                position: absolute;
                left: 0;
                top: 25px;
                z-index: -1
            }
            
            #progressbar li.active:before,
            #progressbar li.active:after {
                background: skyblue
            }
            
            .action-button {
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                color: #FFFFFF;
                padding-left: rem(40);
                padding-right: rem(40);
                line-height: rem(38);
                background-color: #83B56F;
                border-radius: rem(20);
                display: inline-block;
                border: rem(1) solid #83B56F!important;
                border: none;
                text-decoration: none;
                transition: 0.3s all ease-in;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: rem(200);
                text-align: center;
                cursor: pointer;
                &:hover {
                    border: none
                }
            }
            
            .step-content {
                input[type="text"],
                input[type="email"],
                input[type="phone"] {
                    background-color: #EDF0F5;
                    height: rem(45);
                    width: 100%;
                    border-radius: rem(8);
                    border: none;
                    padding-left: rem(8);
                    padding-right: rem(8);
                }
            }
            
            .action-button-previous {
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                color: #FFFFFF;
                padding-left: rem(40);
                padding-right: rem(40);
                line-height: rem(38);
                background-color: #83B56F;
                border-radius: rem(20);
                display: inline-block;
                border: rem(1) solid #83B56F!important;
                border: none;
                text-decoration: none;
                transition: 0.3s all ease-in;
                position: absolute;
                left: rem(60);
                right: 0;
                margin: 0 auto;
                width: rem(200);
                &:hover {
                    border: none
                }
            }
            
            #msform fieldset {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    .action-button {
                        left: rem(305);
                    }
                    .action-button-previous {
                        position: relative;
                        left: 0;
                    }
                }
            }
            
            #msform fieldset {
                &:nth-child(5) {
                    #progressbar {
                        display: none;
                    }
                }
            }
            
            .last-card {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: rem(27);
                    line-height: rem(37);
                    text-align: center;
                    color: #83B56F;
                    margin-top: rem(20);
                }
            }
            
            .step-content {
                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    text-align: center;
                    color: #000000;
                    margin-bottom: rem(10);
                    margin-top: rem(20);
                }
                .inputElement {
                    margin-bottom: rem(10);
                    label {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: rem(17);
                        line-height: rem(22);
                        color: #000000;
                        margin-bottom: rem(5);
                    }
                }
            }
            
            #step_6 {
                height: rem(557);
                display: flex;
                justify-content: center;
                align-items: center;
                h3 {
                    color: #83B56F;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 37px;
                    text-align: center;
                }
            }
            
            .warning {
                color: red!important;
                font-size: rem(14)!important;
            }
            
            .select-styled:active:after,
            .select-styled.active:after {
                width: 92%;
            }
            
            select {
                width: 100%;
                height: rem(50);
                border: rem(1) solid #cbcbcb;
                outline: none;
                padding-left: rem(10);
                padding-right: rem(10);
                font-family: 'Open Sans', sans-serif;
                font-weight: 500;
                color: #717171;
                font-size: rem(16);
            }
            
            .select-styled {
                display: none;
                &:after {
                    background-position: 92%;
                    background-size: rem(18);
                }
            }
            
            .select-options {
                li {
                    font-size: rem(13);
                    font-weight: 700;
                    line-height: rem(24);
                    box-sizing: border-box;
                    color: #000000;
                }
            }
            
            .back_1 {
                display: none;
            }
            
            .InputWarning {
                border: rem(1.5) solid red!important;
            }
            
            .inputElement {
                span {
                    font-family: 'Open Sans', sans-serif;
                    float: left;
                    width: 100%;
                    margin-top: rem(10);
                    color: red;
                    margin-bottom: rem(10);
                    font-size: rem(13);
                    font-weight: 600;
                }
            }

            .vousOnly {
                margin-top: rem(40);
            }
            
            .tools-step {
                float: left;
                width: 100%;
            }
            
            .wpcf7-not-valid-tip {
                font-family: 'Open Sans', sans-serif;
                float: left;
                width: 100%;
                margin-top: rem(10);
                margin-bottom: rem(10);
                font-size: rem(14)!important;
                font-weight: 600!important;
            }
            
            .wpcf7 form.sent .wpcf7-response-output {
                border-color: #46b450;
                border: none;
                font-family: 'Open Sans', sans-serif;
                float: left;
                width: 100%;
                margin-top: rem(10);
                font-weight: 500;
                padding: 0;
                text-align: center;
                font-size: 20px;
                color: #fff;
                background-color: #74BF45;
                padding-top: 20px;
                padding-bottom: 20px;
                line-height: 33px;
                margin-bottom: 30px;
                margin-left:0;
                margin-right:0;
            }
            
            .wpcf7 form.invalid .wpcf7-response-output,
            .wpcf7 form.unaccepted .wpcf7-response-output,
            .wpcf7 form.payment-required .wpcf7-response-output {
              border-color: #dc3232;
              border: none;
              font-family: 'Open Sans', sans-serif;
              float: left;
              width: 100%;
              margin-top: rem(10);
              font-weight: 500;
              padding: 0;
              text-align: center;
              font-size: 20px;
              color: #fff;
              background-color: #dc3232;
              padding-top: 20px;
              padding-bottom: 20px;
              line-height: 33px;
              margin-bottom: 30px;
              margin-left:0;
              margin-right:0;
            }
            .input-stiky{
              .wpcf7 form.sent .wpcf7-response-output {
                border-color: #46b450;
                border: none;
                font-family: 'Open Sans', sans-serif;
                float: left;
                width: 100%;
                margin-top: rem(10);
                font-weight: 500;
                padding: 0;
                text-align: center;
                font-size: 16px;
                color: #fff;
                background-color: #74BF45;
                padding-top: 10px;
                padding-bottom: 10px;
                line-height: 22px;
                margin-bottom: 0px;
                margin-left:0;
                margin-right:0;
              }

            }

            .input-stiky{
              .wpcf7 form.invalid .wpcf7-response-output,
              .wpcf7 form.unaccepted .wpcf7-response-output,
              .wpcf7 form.payment-required .wpcf7-response-output {
                border-color: #dc3232;
                border: none;
                font-family: 'Open Sans', sans-serif;
                float: left;
                width: 100%;
                margin-top: rem(0);
                font-weight: 500;
                padding: 0;
                text-align: center;
                font-size:15px;
                color: #fff;
                background-color: #dc3232;
                padding-top: 10px;
                padding-bottom: 10px;
                line-height: 22px;
                margin-bottom: 10px;
                margin-left:0;
                margin-right:0;
              }
            }
            
            .wpcf7-form-control-wrap {
                float: left;
                width: 100%;
            }
            
            .vousetcojoint-child,
            .childrenAges {
                margin-top: rem(40);
            }
            
            .checkbox-custom input {
                &:hover {
                    cursor: pointer;
                }
            }
            
            .afterAbout {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            #ageEnfant{
              .hidden{
                display:none;
              }
            }

            .action-button{
              position:absolute;
              left:0;
              right:0;
              bottom:rem(32);
            }