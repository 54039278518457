.search-header {
    input {
        background-color: #f2f2f0;
        border: none;
        height: rem(40);
        border-radius: rem(30);
        width: 100%;
        padding-left: rem(20);
        font-family: 'Sofia Pro';
        font-size: rem(14);
    }
}

input:focus {
    outline: none;
}

input[type="checkbox"],
input[type="radio"] {
    margin: 0;
    max-width: 100%;
    height: auto;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: #1b1b1b;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    float: left;
    margin-right: rem(12);
}

.form-element label {
    line-height: rem(18);
    font-size: rem(16);
    color: $anthrazit;
    font-family: 'CalibriRegular';
}

input[type="radio"] {
    float: left;
}

input[type="radio"] {
    width: rem(25);
    height: rem(25);
    border-color: #EDF0F5;
    background-color: #EDF0F5;
    border-radius: 50%;
}

input[type="checkbox"] {
    width: rem(25);
    height: rem(25);
    border-color: #EDF0F5;
    background-color: #ffffff;
    border: rem(1) solid #5D9746
}

input[type="checkbox"]:checked {
    background-position: center;
    background-repeat: no-repeat;
    border-color: #EDF0F5;
    background-color: #5D9746;
}

input[type="radio"]:checked+label {
    color: #74BF45;
    font-weight: 800;
}

.loop-input-radio {
    position: relative;
    margin-bottom: rem(25);
    width: 100%;
    display: inline-block;
    label {
        float: left;
        width: auto!important;
        font-style: normal;
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(25);
        color: #000000;
        @include bp-lt($small) {
            font-size: rem(14);
        }
    }
}

input[type=radio]:checked:after {
    opacity: 1;
}

input[type=radio]:after {
    content: "";
    opacity: 0;
    display: block;
    background-color: #5D9746;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 50%;
}

.inputElement {
    margin-bottom: rem(10);
}

.checkbox-custom {
    margin-top: rem(0);
    margin-bottom: rem(0);
    float: left;
    width: 100%;
    label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400!important;
        font-size: 11px!important;
        line-height: 15px!important;
        color: #000000!important;
        float: right;
        width: 90%;
        a {
            color: #74BF45;
        }
    }
}

#s5 {
    #progressbar {
        li {
            width: 100%;
            background-color: #83B56F;
        }
    }
}

#s4 {
    #progressbar {
        li {
            width: 70%;
            background-color: #83B56F;
        }
    }
}

#s3 {
    #progressbar {
        li {
            width: 50%;
            background-color: #83B56F;
        }
    }
}

#s2 {
    #progressbar {
        li {
            width: 30%;
            background-color: #83B56F;
        }
    }
}

.tools-back {
    position: relative;
    top: rem(18);
    left: -7px;
    span {
        color: #5D9746;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: rem(16);
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}