// ========================================= //
// colors
// ========================================= //
//Primary
$primary :#510d29!default;
$white :#ffffff!important;
$black :#000000!default;
//Grey tones
$anthrazit :#353535!default;
$grey :#515151!default;
$grey--01 :#666666!default;
$grey--02 :#808080!default;
$grey--03 :#B4B4B4!default;
$grey--04 :#D2D2D2!default;
$grey--05 :#E5E5E5!default;
$grey--06 :#F4F4F4!default;
$grey--07 :#F9F9F9!default;
//Secondary colors
$violet :#6D1874!default;
$ocker :#C3B300!default;
$orange :#D3822B!default;
$bordeaux :#A11C36!default;