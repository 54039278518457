/*Contact Page*/

.rowInput {
    float: left;
    width: 100%;
    margin-bottom: rem(10);
    label {
        float: left;
        width: 100%;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(28);
        text-align: left;
        color: #000000;
        margin-bottom: rem(5);
        span {
            color: red;
        }
    }
    input {
        float: left;
        width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
        background-color: #EDF0F5;
        height: rem(55);
        width: 100%;
        border-radius: rem(8);
        border: none;
        padding-left: rem(8);
        padding-right: rem(8);
    }
    input[type="submit"] {
        padding-left: rem(50);
        padding-right: rem(50);
        float: right;
        width: auto;
        line-height: rem(50);
        background-color: #74BF45;
        color: #fff;
        border: rem(2) solid #74BF45;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: rem(18);
        letter-spacing: rem(1);
        border-radius: rem(20);
        &:hover {
            background-color: #fff;
            color: #74BF45;
        }
    }
    textarea {
        background-color: #EDF0F5;
        width: 100%;
        height: rem(120);
        border-radius: rem(8);
        border: none;
        padding-left: rem(8);
        padding-right: rem(8);
        padding-top: rem(10);
        &:focus {
            outline: none;
        }
    }
}

.contactContent {
    h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 39.11px;
        line-height: 53px;
        color: #74BF45;
    }
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #3F3F3F;
        margin-bottom: rem(20);
        margin-top: rem(10);
        max-width: 80%;
        @include bp-lt($small) {
            max-width: 100%;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        li {
            width: 100%;
            margin-bottom: rem(25);
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: rem(16);
                color: #3F3F3F;
                a {
                    color: #74BF45;
                    margin-left: rem(10);
                }
            }
        }
    }
}