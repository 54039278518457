.slide-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.home-slides {
    .item {
        min-height: rem(690);
    }
}

.slide_content {
    position: relative;
    top: rem(40);
    z-index: 8;
    h4 {
        font-style: normal;
        font-weight: 800;
        font-size: rem(50);
        line-height: rem(60);
        color: #363636;
    }
    p {
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #363636;
    }
    h2 {
        font-style: normal;
        font-weight: 800;
        font-size: rem(49);
        line-height: rem(60);
        letter-spacing: 0.03em;
        color: #74BF45
    }
    h3 {
        font-weight: 600;
        font-size: rem(38);
        line-height: rem(50);
        color: #363636;
        strong {
            font-weight: 800;
        }
        span {
            color: #74BF45;
            font-weight: 800;
        }
    }
}

.slideTrust {
    img {
        width: 80%;
        margin-top: 30px;
    }
}

.image_partner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: rem(150);
    min-height: rem(150);
    img {
        max-height: rem(150);
        max-width: rem(150);
    }
}

#home_partner {
    margin-top: rem(40);
    text-align: center;
    position: relative;
    .container {
        padding-top: rem(20);
        padding-bottom: rem(40);
        background-color: #ffffff;
    }
    h3 {
        font-style: normal;
        font-weight: 800;
        font-size: rem(35);
        line-height: 53px;
        color: #2A2A2A;
        margin-bottom: rem(30);
    }
    .owl-nav {
        position: absolute;
        top: -3px;
        width: 100%;
        z-index: 2;
        left: 0;
        height: rem(70);
        right: 0;
        margin: 0;
        @include bp-lt($small) {
            top: rem(20);
        }
        button {
            width: rem(62);
            height: rem(62);
            background-color: #ffffff;
            border-radius: 50%;
            position: absolute;
            background-image: url("../../../medias/images/arrow.svg");
            background-repeat: no-repeat;
            background-position: center center;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
            &:hover {
                background-color: #74BF45;
                background-image: url("../../../medias/images/arrowWhite.svg");
            }
            span {
                font-size: 0;
            }
        }
        .owl-prev {
            left: rem(-40);
            @include bp-lt($small) {
                left: rem(0);
            }
        }
        .owl-next {
            right: rem(-40);
            transform: rotate(180deg);
            @include bp-lt($small) {
                right: rem(0);
            }
        }
    }
}

.partner-slides {
    padding-left: rem(20);
    padding-right: rem(20);
}

.home-slides {
    .owl-dots {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: rem(20);
    }
}

#homeSlides {
    max-width: 90%!important;
    @include bp-lt($small) {
        display: none;
    }
}