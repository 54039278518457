.top-header-social {
    ul {
        margin-top: rem(14);
    }
    li {
        display: inline-block;
        margin-left: rem(5);
        a {
            background-color: #000000;
            width: rem(28);
            height: rem(28);
            font-size: 0;
            display: block;
            border-radius: 50%;
            text-align: center;
            img {
                width: rem(23);
                margin-top: rem(3);
            }
        }
    }
}

.avis-client {
    margin-right: rem(20);
    span {
        background-color: #74BF45;
        height: rem(28);
        width: rem(100);
        display: block;
        line-height: rem(28);
        border-radius: 0 rem(15) rem(15) 0;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 26px;
        color: #FFFFFF;
        text-align: center;
        margin-left: rem(10);
        a {
            color: #FFFFFF;
        }
    }
    li {
        display: inline-block;
        margin-top: rem(15);
    }
}

.top-header-right {
    display: flex;
    justify-content: end;
    margin-bottom: rem(25);
}

.sub-header {
    display: flex;
    justify-content: end;
    margin-bottom: rem(40);
    li {
        display: inline-block;
        margin-left: rem(40);
        img {
            margin-right: 10px;
            margin-top: -5px;
        }
        a {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #2B2B2B;
            &:hover {
                color: #74BF45;
            }
        }
    }
}

.header-logo {
    img {
        width: rem(330);
        margin-top: rem(30);
    }
}

.stiky-call {
    position: fixed;
    top: rem(263);
    z-index: 999;
    left: 0;
    @include bp-lt($small) {
        display: none;
    }
    .stiky-call-btn {
        width: rem(165);
        background-color: #74BF45;
        height: rem(45);
        border-radius: 0 rem(30) rem(30) 0;
        line-height: rem(61);
        color: #FFFFFF;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        border: rem(2) solid #74BF45;
        box-sizing: border-box;
        padding-left: rem(5);
        text-decoration: none;
        transition: 0.3s all ease-in;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            float: right;
            padding-right: 6px;
            font-size: 15px;
            font-weight: 700;
        }
        img {
            margin-right: 15px;
            position: relative;
            top: -1px;
            height: 1.5rem;
        }
        &:hover {
            background-color: #FF7769;
            border-color: #FF7769;
        }
        .open {
            display: block;
        }
        .close {
            display: none;
        }
    }
    .content-call-skity {
        left: rem(60);
        position: absolute;
        top: 0;
        width: rem(355);
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        h3 {
            background-color: #74BF45;
            height: rem(55);
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            position: relative;
            padding-top: rem(4);
            border-radius: 0 23px 0 0px;
        }
        .stiky-footer {
            height: rem(56);
            background-color: #C4C4C4;
            border-radius: 0 0 rem(30) 0;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                text-align: center;
                color: #686868;
            }
        }
        .container-stiky {
            background-color: #FFFFFF;
            padding-top: rem(20);
            padding-bottom: rem(20);
            border-top: rem(4) solid rgba(60, 53, 143, 0.25);
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            h4 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #282828;
                margin-top: rem(10);
                margin-bottom: rem(10);
            }
            input[type="submit"] {
                background-color: #74BF45;
                border-radius: rem(20);
                border: rem(1) solid #74BF45;
                color: #FFFFFF;
                line-height: rem(42);
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: rem(40);
                text-align: center;
                color: #FFFFFF;
                margin-top: rem(10);
                width: 80%;
                &:hover {
                    background-color: #FF7769;
                    border-color: #FF7769;
                }
            }
            input[type="text"],
            input[type="tel"] {
                background-color: #EDF0F5;
                border-radius: rem(10);
                border: rem(1) solid #EDF0F5;
                color: #FFFFFF;
                line-height: rem(51);
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                text-align: center;
                color: #A2A9B4;
                margin-top: rem(10);
                width: 100%;
            }
        }
        .input-stiky {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }
}

.activeStiky {
    .stiky-call-btn {
        width: rem(55);
        height: rem(55);
        border-radius: 50%;
        left: rem(10);
        span {
            display: none;
        }
    }
    .content-call-skity {
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
    }
    .open {
        display: none!important;
    }
    .close {
        display: block!important;
        left: 3px;
        top: -1px!important;
    }
}

.banner-pages {
    .banner-content {
        @include bp-lt($small) {
            display: none;
        }
    }
}

.icon-contact {
    background-image: url("../../../medias/images/contact.png");
}

.icon-about {
    background-image: url("../../../medias/images/contact.png");
}

.icon-menu {
    background-repeat: no-repeat;
    width: rem(36);
    height: rem(36);
    float: left;
    margin-right: rem(5);
    background-position: center center;
}

.item-menu-contant {
    position: relative;
    &:hover {
        &:before {
            content: "";
            background-color: #F1F0FD;
            height: rem(6);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: rem(-10);
            border-radius: rem(2);
        }
        .icon-contact {
            background-image: url("../../../medias/images/contact-color.png");
        }
    }
}

.item-menu-about {
    .icon-about {
        background-image: url("../../../medias/images/about.png");
    }
    &:hover {
        position: relative;
        &:before {
            content: "";
            background-color: #F1F0FD;
            height: rem(6);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: rem(-10);
            border-radius: rem(2);
        }
        .icon-about {
            background-image: url("../../../medias/images/about-color.png");
        }
    }
}

.banner-pages {
    &:after {
        background-image: url("../../../medias/images/topLeft.png");
        content: "";
        width: 287px;
        height: rem(838);
        position: absolute;
        left: 0;
        top: 10px;
        background-repeat: no-repeat;
    }
    &:before {
        background-image: url("../../../medias/images/topRight.png");
        content: "";
        width: 722px;
        height: rem(1018);
        position: absolute;
        right: 0;
        top: 10px;
        background-repeat: no-repeat;
        z-index: -1;
    }
}

.banner-sider {
    &:after {
        background-image: url("../../../medias/images/topLeft.png");
        content: "";
        width: 287px;
        height: rem(838);
        position: absolute;
        left: 0;
        top: 10px;
        background-repeat: no-repeat;
        @include bp-lt($small) {
            content: none;
        }
    }
    &:before {
        background-image: url("../../../medias/images/topRight.png");
        content: "";
        width: 722px;
        height: rem(1018);
        position: absolute;
        right: 0;
        top: 10px;
        background-repeat: no-repeat;
        z-index: -1;
        @include bp-lt($small) {
            content: none;
        }
    }
}

.mobileTop {
    @include bp-lt($small) {
        order: 1;
        .sub-header {
            display: none;
        }
        .top-header-right {
            margin-bottom: rem(5);
        }
    }
}

.mobileMenu {
    .mobile-nav {
        display: none;
    }
    @include bp-lt($small) {
        order: 2;
        margin-bottom: rem(20);
        .header-logo {
            width: 50%;
            float: left;
            img {
                width: 100%;
            }
        }
        .mobile-nav {
            display: block;
            float: right;
            width: 50%;
        }
    }
}

.content-navigation_header {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    margin-top: rem(20);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
    background-color: #74BF45;
    text-align: center;
    li {
        margin-bottom: rem(15);
        a {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 33px;
            color: #FFFFFF;
        }
    }
}

.content-navigation_header_active {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    min-height: 125px;
}

.icon-navigation_header {
    text-align: right;
    margin-top: 42px;
    cursor: pointer;
}

.antispam {
    label {
        color: red!important;
    }
}
.need-healp-header{
    margin-right:rem(15);

    margin-top:rem(24);
p{
    font-family: 'Open Sans';
    color:#2B2B2B;
    font-weight:600;
    font-size:rem(16);
    a{
        color:#74BF45;
        &:hover{
            text-decoration:underline;
            color:#74BF45;
        }
    }
}
}


