.product-tabs{

    .nav-tabs{
        border-bottom:0;
        margin-bottom:rem(50);
    }

    .nav-item{
        margin-left:rem(20);
        margin-right:rem(20);
        display:flex;
        align-items:center;
        @include bp-lt($small){
            flex-direction: column;
            margin-bottom:rem(20);
        }
        &:after{
           content:"\f109";
           font-family: flaticon !important;
           display:inline;
           color:#f6454e;
           font-size:rem(20);
           position: relative;
           left:rem(18);
           @include bp-lt($small){
                left:0;
                top:rem(15);
            }
        }
        &:last-child:after{
            content:none;
        }
    }
    .nav-link{
        font-family: 'Sofia Pro';
        font-weight:800;
        font-size:rem(22);
        padding-left:rem(20);
        padding-left:rem(20);
        line-height:rem(30);
        color: #000;
        text-transform:uppercase;
        transition:none;
        border:2px solid transparent!important;
      
        &:hover{
            color:#fff;
            border:2px dotted #f6454e;
            border-radius:30px;
            background-color:#f6454e;
        }
        &:focus{
            outline:none;
            border:none!important;

        }
        
    }
    .nav-link.active{
        color:#fff;
        border:2px dotted #f6454e;
        border-radius:30px;
        background-color:#f6454e;
    }
}

/*Tabs Single*/
#tabs-single{
    #myTab{
        border:none;
        margin-bottom:rem(30);
        .nav-link{
            border:none;
            text-transform: uppercase;
            padding: 0;
            margin: 0 5rem rem(-2) 0;
            font-weight: 500;
            background-color: transparent;
            font-size: rem(16);
            padding: rem(5) 0;
            font-family: 'Sofia Pro';
            font-weight:500;
            font-size:rem(15);
            color:#000;
            &:hover{
                border-bottom:2px solid #193a43;
                color:#193a43;
            }
        }
        .nav-link.active{
                border-bottom:2px solid #193a43;
                color:#193a43;
           
        }
    }

    

}

 