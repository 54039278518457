.btn-primary {
    transition: 0.3s all ease-in;
    color: $white;
    font-size: rem(16);
    min-height: rem(48);
    display: inline-block;
    line-height: rem(53);
    padding-left: rem(35);
    padding-right: rem(22);
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
    &:after {
        content: "\e927";
        font-family: 'icomoon';
        color: $white;
        font-size: rem(16);
        position: relative;
        top: rem(3);
        margin-left: rem(15);
    }
}

.btn-secondary {
    transition: 0.3s all ease-in;
    background-color: $anthrazit;
    color: $white;
    font-size: rem(16);
    min-height: rem(48);
    &:hover {
        background-color: $grey--01;
    }
}

.btn-ghost {
    transition: 0.3s all ease-in;
    background-color: $white;
    color: $anthrazit;
    font-size: rem(16);
    min-height: rem(48);
    &:hover {
        background-color: $white;
    }
}

.text-link-normal {
    transition: 0.3s all ease-in;
    background-color: transparent;
    min-height: rem(48);
    font-family: 'legacyBook';
    font-size: rem(16);
    line-height: rem(18);
    color: $anthrazit;
    &:hover {
        text-decoration: none;
    }
    &:after {
        content: "\e927";
        font-family: 'icomoon';
        font-size: rem(16);
        position: relative;
        top: rem(3);
        margin-left: rem(3);
    }
}