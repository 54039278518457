.ClientContent {
    background-color: #F1FFE9;
    border-radius: rem(21);
    padding-top: rem(30);
    padding-bottom: rem(30);
    padding-left: rem(30);
    padding-right: rem(30);
    @include bp-lt($small) {
        margin-bottom: rem(30);
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 38px;
        color: #3F3F3F;
    }
}

.ourClient {
    margin-top: rem(40);
    h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 39.11px;
        line-height: 53px;
        color: #272727;
        margin-bottom: rem(20);
    }
    h4 {
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: #272727;
        margin-bottom: rem(20);
    }
}

.ClientRevew {
    overflow: hidden;
    img {
        width: 100%;
    }
}

.afterAbout {
    margin-top: rem(30);
    margin-bottom: rem(70);
    p {
        font-style: normal;
        font-weight: 600;
        font-size: rem(11);
        line-height: rem(18);
        text-align: center;
        color: #303030;
        font-family: 'Open Sans', sans-serif;
        margin-bottom: rem(10);
        strong {
            font-weight: 700;
        }
    }
}

.about-item {
    display: flex;
    margin-bottom: rem(40);
    .about-icon {
        display: flex;
        align-items: center;
        min-width: rem(94);
        justify-content: center;
    }
    h4 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #272727;
    }
    p {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #3F3F3F;
    }
    .about-content-item {
        padding-left: rem(10);
    }
}

.homeAbout {
    margin-bottom: rem(100);
    margin-top: rem(70);
    @include bp-lt($small) {
        margin-bottom: rem(50);
    }
    &:before {
        content: "";
        background-image: url("../../../medias/images/bg1.svg");
        width: 900px;
        height: 1034px;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: -200px;
        z-index: -1;
        @include bp-lt($small) {
            width: 100%;
        }
    }
    .aboutContent {
        margin-top: rem(60);
        padding-left: rem(100);
        @include bp-lt($small) {
            padding-left: 0;
        }
    }
    h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 39.11px;
        line-height: 53px;
        color: #272727;
        margin-bottom: rem(30);
        margin-top: rem(30);
    }
}

.about-right-image {
    margin-top: rem(30);
    @include bp-lt($small) {
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    img {
        width: 80%;
        float: right;
    }
}

.page_content {
    h1 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 39.11px;
        line-height: rem(60);
        color: #74BF45;
    }
    h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: rem(35);
        line-height: rem(60);
        color: #272727;
    }
    h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: rem(28);
        line-height: rem(50);
        color: #272727;
    }
    h4 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: rem(25);
        line-height: rem(50);
        color: #272727;
    }
    h5 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: rem(20);
        line-height: rem(45);
        color: #272727;
    }
    h6 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(40);
        color: #272727;
    }
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: rem(15);
        line-height: rem(24);
        margin-bottom: rem(10);
        strong {
            font-weight: 700;
        }
    }
    a {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: rem(15);
        line-height: rem(24);
        margin-bottom: rem(10);
        color: #74BF45;
        &:hover {
            text-decoration: underline;
        }
    }
    ul {
        padding-left: rem(10);
        display: flex;
        flex-direction: column;
        margin-top: rem(10);
        li {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: rem(15);
            line-height: rem(24);
            margin-bottom: rem(10);
            float: left;
            width: 100%;
            &::before {
                content: "";
                width: rem(10);
                height: rem(10);
                background-color: #74BF45;
                display: block;
                border-radius: 50%;
                margin-right: rem(10);
                margin-top: rem(8);
                float: left;
            }
            ul {
                li {
                    line-height: rem(22);
                    margin-bottom: rem(8);
                }
            }
        }
    }
    blockquote {
        background-color: #F1FFE9;
        width: 100%;
        padding-top: rem(30);
        padding-bottom: rem(30);
        text-align: center;
        margin-top: rem(20);
        margin-bottom: rem(20);
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: rem(20);
        line-height: rem(24);
        color: #272727;
        p {
            font-weight: 700;
            font-size: rem(20);
            line-height: rem(24);
            color: #272727;
        }
    }
}

.banner-content {
    position: absolute;
    right: rem(160);
    top: rem(30);
    width: 38%;
    h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: rem(37);
        line-height: rem(50);
        color: #363636;
        span {
            color: #74BF45;
        }
        strong {
            font-weight: 800;
        }
    }
}

.screen-reader-response {
    display: none;
}

.wpcf7-response-output {
    display: flex;
}